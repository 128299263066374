<template>
    <div class="layout-border">
        <div class="layout-border__content">
            <div class="layout">
                <div class="layout__main">
                    <slot />
                </div>
            </div>

            <ClientOnly>
                <TheCookiebar />
                <TheLoadingIndicator />
            </ClientOnly>
        </div>
    </div>
</template>

<script setup>
const appConfig = useAppConfig();

useFavicon();
useFeatureEnabled('quickscan');

const i18nHead = useLocaleHead({
    addDirAttribute: true,
    addSeoAttributes: true
});

useHead({
    htmlAttrs: {
        ...i18nHead.value.htmlAttrs,
    },
    bodyAttrs: {
        'data-theme': appConfig.theme
    }
});
</script>
